import React, { useContext } from "react"
import { GlobalStateContext } from './../../../context/GlobalContextProvider';

export const PricingCard = ({ pricingModels }) => {
  const state = useContext(GlobalStateContext);
  const { language } = state;
  if (!pricingModels) { return <></>; }
  return (
    <>
      {pricingModels.map(
        (
          {
            pricingType,
            monthlyPrice,
            subscriptionPrice,
            discount,
            features,
          },
          index
        ) => {
          const basic = pricingType.toLowerCase() === "basic"
          return (
            <div
              key={index}
              className={`col-md-5 col-xl-4 mb-3${
                index % 2 === 0 ? " offset-md-1 offset-xl-2" : ""
              }${basic ? " price-card_basic_small" : ""}`}
            >
              <article className="card-news light-shadow prices-card">
                <div className="card-news-header pt-6 text-align-center">
                  <h4 className="mt-4 mb-0">{pricingType}</h4>
                  {basic ? (
                    <div className="row">
                      <div className="col-3 mx-auto px-0">
                        <strong className="price-card__price">0.00€</strong>
                        <div className="small price-card__price card__duration price-card__grey">
                          {language === "de" ? "Monatlich" : "Monthly"}
                        </div>
                      </div>
                    </div>
                  ) : monthlyPrice && (
                    <div className="row">
                      <div className="col-3 ml-auto px-0">
                        <strong className="price-card__price">
                          {monthlyPrice}
                        </strong>
                        <div className="small price-card__price card__duration price-card__grey">
                          {language === 'de' ? 'Monatlich' : 'Monthly'}
                        </div>
                      </div>
                      <div className="col-1 mt-3 px-0">
                        <span className="price-card__price price-card__grey price-card__divider"></span>
                      </div>
                      <div className="col-3 mr-auto px-0">
                        <strong className="price-card__price">
                          {subscriptionPrice} 
                        </strong>
                        <div className="small price-card__price card__duration price-card__grey">
                          {language === 'de' ? '6 Monate' : '6 Monthly'}
                          <span className="price-card_discount">
                            {discount}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`card-news-body${
                    basic ? " price-card__price-basic" : ""
                  }`}
                >
                  <ol className="fa-ul">
                    {Object.keys(features).map((key, index) => {
                      return (
                        <li
                          key={index}
                          className={`price-card__li${basic ? "" : " premium"}`}
                        >
                          <i className="fa-li fa fa-check" />
                          <strong>{key}</strong>
                          <div className="small">{features[key]}</div>
                        </li>
                      )
                    })}
                  </ol>
                </div>
              </article>
            </div>
          )
        }
      )}
    </>
  )
}
